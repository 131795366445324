import { Injectable } from '@angular/core'

export const BROKERS_STORAGE = 'brokers'
export const FAST_PAYMENT_STORAGE = 'fastPayment'
export const LOAN_STORAGE = 'loan'
export const NEXT_URL = 'nextUrl'
export const PAYMENT_AMOUNT_STORAGE = 'paymentAmount'
export const SHARED_DATA_STORAGE = 'sharedData'
export const SIGNUP_DETAILS_STORAGE = 'signupDetails'
export const REQUISITION_STORAGE = 'requisition'
export const TRANSACTIONS_SESSION_STORAGE = 'transactionsSession'
export const WITHDRAW_AMOUNT_STORAGE = 'withdrawAmount'

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  data = {}

  constructor() {}

  getData(key: string) {
    // Get value from service data container if exists
    if (this.data[key] !== null && this.data[key] !== undefined) return this.data[key]

    // Try to get value from storage data container
    const sharedData = localStorage.getItem(SHARED_DATA_STORAGE)
    const storageData = sharedData ? JSON.parse(sharedData) : {}
    if (storageData.hasOwnProperty(key)) return storageData[key]

    return null
  }

  reset(): void {
    this.data = {}
    localStorage.removeItem(SHARED_DATA_STORAGE)
  }

  resetData(key: string): void {
    // Reset service data container
    if (this.data.hasOwnProperty(key)) delete this.data[key]

    // Reset storage data container
    const sharedData = localStorage.getItem(SHARED_DATA_STORAGE)
    const storageData = sharedData ? JSON.parse(sharedData) : {}
    delete storageData[key]
    localStorage.setItem(SHARED_DATA_STORAGE, JSON.stringify(storageData))
  }

  setData(key: string, data): void {
    // Init service data container
    this.data[key] = data

    // Init storage data container
    const sharedData = localStorage.getItem(SHARED_DATA_STORAGE)
    const storageData = sharedData ? JSON.parse(sharedData) : {}
    storageData[key] = data
    localStorage.setItem(SHARED_DATA_STORAGE, JSON.stringify(storageData))
  }
}
