// istanbul ignore file

import { createReducer, on } from '@ngrx/store'
import { AppActions } from './app.actions'
import { initialState } from './app.state'

export const appReducer = createReducer(
  initialState,
  on(AppActions.enablePaymentButton, state => ({ ...state, paymentAvailable: true })),
  on(AppActions.disablePaymentButton, state => ({ ...state, paymentAvailable: false })),
  on(AppActions.enableFastPayments, state => ({ ...state, fastPaymentsEnabled: true })),
  on(AppActions.disableFastPayments, state => ({ ...state, fastPaymentsEnabled: false })),
  on(AppActions.hideCardMenu, state => ({ ...state, cardMenuVisible: false })),
  on(AppActions.showCardMenu, state => ({ ...state, cardMenuVisible: true })),
  on(AppActions.hideDashboardLink, state => ({ ...state, dashboardLinkVisible: false })),
  on(AppActions.showDashboardLink, state => ({ ...state, dashboardLinkVisible: true }))
)
