import { NgModule } from '@angular/core'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { NgxMaskModule } from 'ngx-mask'
import { TranslateModule } from '@ngx-translate/core'
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'
import { CommonModule, CurrencyPipe } from '@angular/common'
import { PhoneCodeInputDirective } from './directives/phone-code-input.directive'
import { ApiService } from './services/api.service'
import { FocusInvalidInputDirective } from './directives/focus-invalid-input.directive'
import { ApiInterceptor } from './services/api.interceptor'
import { BanksListComponent } from './components/banks-list/banks-list.component'
import { BanksFilterPipe } from './pipes/banks-filter.pipe'
import { FormsModule } from '@angular/forms'
import { CurrPipe } from './pipes/curr.pipe'
import { TooltipModule } from 'ngx-bootstrap/tooltip'
import { AccountPipe } from './pipes/account.pipe'
import { LoadingComponent } from './components/loading/loading.component'
import { SafeUrlPipe } from './pipes/safe-url.pipe'
import { BankidAuthenticatorComponent } from './components/bankid-authenticator/bankid-authenticator.component'

const INTERCEPTOR_PROVIDER = {
  provide: HTTP_INTERCEPTORS,
  useClass: ApiInterceptor,
  multi: true,
}

@NgModule({
  declarations: [
    BanksFilterPipe,
    BanksListComponent,
    FocusInvalidInputDirective,
    PhoneCodeInputDirective,
    SafeUrlPipe,
    AccountPipe,
  ],
  imports: [
    CurrPipe,
    BsDatepickerModule,
    CommonModule,
    HttpClientModule,
    NgxMaskModule,
    TranslateModule,
    FormsModule,
    TooltipModule,
    LoadingComponent,
    BankidAuthenticatorComponent,
  ],
  exports: [
    AccountPipe,
    BanksFilterPipe,
    BanksListComponent,
    BsDatepickerModule,
    CommonModule,
    CurrPipe,
    FocusInvalidInputDirective,
    HttpClientModule,
    LoadingComponent,
    NgxMaskModule,
    PhoneCodeInputDirective,
    TranslateModule,
    TooltipModule,
  ],
  providers: [ApiService, BanksFilterPipe, INTERCEPTOR_PROVIDER, CurrencyPipe],
})
export class SharedModule {}
