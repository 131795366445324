<section class="flow-root">
  <div class="flow-root__wrapper">
    <header class="header">
      <div class="container-fluid">
        <div class="row">
          <div
            class="col-12 col-lg-5 order-1 order-lg-0 d-flex align-items-center justify-content-center justify-content-lg-start mt-3 mt-lg-0"
          >
            <img src="/assets/images/logo.svg" width="270" alt="" />
          </div>
          <div
            class="col-12 col-lg-7 order-0 order-lg-1 d-flex flex-lg-column align-items-center justify-content-between align-items-lg-end header__panel"
          >
            <div class="d-inline d-lg-block">
              <span class="d-none d-lg-inline">{{ 'FlowLayout.help' | translate }}</span>
              <img src="/assets/images/icons/phone.svg" alt="" class="header__icon mx-1" />
              <a [href]="config.getSetting('tel_phone')" class="header__phone">{{ config.getSetting('phone') }}</a>
              <span class="d-none d-lg-inline ms-1"> | {{ 'FlowLayout.text1' | translate }} 09-16</span>
            </div>
          </div>
        </div>
      </div>
    </header>

    <main>
      <div class="container-fluid">
        <!--<div class="alert alert-light mb-4 p-2 text-smaller text-center">
        Telefonerne er lukket i dag fra 9-16 på grund af sygdom. Du er velkommen til at sende os en email på
        <a href="mailto:kundeservice@nordcredit.dk">kundeservice&#64;nordcredit.dk</a>. Med venlig hilsen NordCredit
        kundeservice
      </div>-->
        @if (serviceNotification && serviceNotification.loanprocess) {
        <div [class]="serviceNotification.style">
          {{ serviceNotification.message }}
        </div>
        }
        <router-outlet></router-outlet>
      </div>
    </main>

    <footer class="footer">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-lg-5 d-none d-lg-flex align-self-center">
            <img src="/assets/images/logo-footer.svg" width="270" alt="" />
          </div>
          <div class="col-12 col-lg-7 footer__details">
            <address class="footer__address">
              <p>GF Money Consumer Finance Aps</p>
              Carl Jacobsen vej 16<br />
              opgang 6, 4.sal<br />
              2500 valby
            </address>

            <nav class="footer__nav mt-1 mt-lg-0 ms-lg-15 ms-xl-20">
              <p>{{ 'FlowLayout.openTime' | translate }} 9-16</p>
              <img src="/assets/images/icons/cellar.svg" alt="" class="me-1" />{{ 'FlowLayout.phone' | translate }}:
              <a [href]="config.getSetting('tel_phone')">{{ config.getSetting('phone') }}</a
              ><br />
              <img src="/assets/images/icons/email.svg" alt="" class="me-1" /><a
                [href]="config.getSetting('mailto_email')"
                >{{ config.getSetting('email') }}</a
              >
            </nav>
          </div>
        </div>
      </div>
    </footer>
  </div>
</section>
