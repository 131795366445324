import { Component, input } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'

@Component({
  standalone: true,
  imports: [TranslateModule],
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {
  message = input<string>('LoadingComponent.pleaseWait')
  showInline = input<boolean>(false)
  showSmall = input<boolean>(false)
  showText = input<boolean>(true)
}
