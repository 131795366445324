export const appStore = 'appStore'

export interface AppState {
  cardMenuVisible: boolean
  dashboardLinkVisible: boolean
  fastPaymentsEnabled: boolean
  paymentAvailable: boolean
}

export const initialState: AppState = {
  cardMenuVisible: false,
  dashboardLinkVisible: false,
  fastPaymentsEnabled: true,
  paymentAvailable: false,
}
