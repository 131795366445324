import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlTree } from '@angular/router'
import { Observable } from 'rxjs'
import { AuthService } from '@shared/services/auth.service'

@Injectable()
export class AuthGuard {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean | UrlTree {
    return this.auth.isAuthenticated() ? true : this.router.parseUrl('/dashboard/login')
  }

  canLoad(route: Route): boolean {
    return route.path.startsWith('dashboard')
  }
}
