/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { AppModule } from './app/app.module'
import { environment } from './environments/environment'
import * as Sentry from '@sentry/angular-ivy'

const serverErrorsRegex = new RegExp('401 Unauthorized|403 Forbidden|Non-Error exception captured with keys', 'mi')

Sentry.init({
  dsn: 'https://3d476376382041d093a2789ee6a7a284@o865186.ingest.sentry.io/6260630',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: 0.1,
  tracePropagationTargets: ['localhost', environment.apiHost],
  ignoreErrors: [serverErrorsRegex],
})

if (environment.production) {
  enableProdMode()
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err))
